import * as React from 'react';
import styled from 'styled-components';
import Button from '../components/button';
import Section from '../components/sections';
import { SectionHero } from '../components/sections/general';
import { Important } from '../components/text';
import { Card } from '../components/card';
import Layout from '../layouts/staticLayout';
import { Hero } from '../components/sections/general/hero/hero.style';
import Seo from '../components/sections/general/seo';

const DownloadCard = styled.a`
    ${Card};
    padding: 2rem;
    min-height: 150px;
    width: 275px;
    transition: transform 150ms ease;
    p {
        text-align: center;
    }
    &:hover {
        transform: scale(1.1);
    }
`;
const HeroCustom = styled(Hero)`
    display: flex;
    gap: 2rem;
    padding-top: 6rem;
    article {
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: center;
    }
    h1 {
        text-align: center;
    }
`;
export default () => {
    return (
        <Layout>
            <Seo title="Dokumenty" />
            <HeroCustom>
                <h1>Dokumenty</h1>
                <article>
                    <DownloadCard href="/content/Smlouva-Greennet_30Mb.pdf" download>
                        <p>Smlouva - GreenNet s.r.o.</p>
                    </DownloadCard>
                    <DownloadCard href="/content/VOP-od-1_1_2021.pdf" download>
                        <p>VOP od 1.1.2021</p>
                    </DownloadCard>
                    <DownloadCard href="/content/GDPR.pdf" download>
                        <p>GDPR</p>
                    </DownloadCard>
                    <DownloadCard href="/content/zmena_poskytovatele.pdf" download>
                        <p>Změna poskytovatele</p>
                    </DownloadCard>
                </article>
            </HeroCustom>
        </Layout>
    );
};
